<template>
  <div id="tabbar" class="p-rel" style="width: 846px">
    <div
      class="durations nav-bar-wrap"
      :class="isFixTabbar ? 'fix-bar' : ''"
      style="overflow-x: auto"
      :key="tabbarKey"
    >
      <div class="nav-bar">
        <div
          v-for="(item, i) in durations"
          :key="i"
          class="nav-item"
          :class="item.active ? 'active' : ''"
          :dataDuration="item.sale_time"
          @click="onNavClick(i)"
        >
          <span class="duration-time">{{
            moment(item.sale_time).format("HH:mm")
          }}</span>
          <span class="duration-text">{{
            moment(item.sale_time).isBefore(
              moment().add(1, "days").startOf("day")
            )
              ? textMap[item.status]
              : "明日预告"
          }}</span>
        </div>
      </div>
    </div>
    <div class="m-b-0 nav-bar-placeholder" v-show="isFixTabbar">
      {{ isFixTabbar }}
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "goods-tabbar",
  props: {
    durations: Array,
  },
  data() {
    return {
      moment,
      textMap: {
        0: "已开抢",
        1: "抢购中",
        2: "即将开始",
      },
      tabbarKey: 0,
      isFixTabbar: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    // 点击导航高亮自己并传递给good-list组件，需要forceRender
    onNavClick(idx) {
      this.$emit("tabbar-click", idx);
      // this.durations.forEach((e, i) => {
      //   if (i == idx) {
      //     e.active = true;
      //   } else {
      //     e.active = false;
      //   }
      // });

      // this.forceRender();

      // this.$refs.content_swiper.slideTo(idx);
    },
    refresh() {
      this.tabbarKey += 1;
    },
  },
  mounted() {

    // 监听页面滚动，移动到导航栏时，让它固定到顶部，并且启动站位块以避免页面跳动
    var self = this;
    document.onscroll = function () {
      var pageScrollTop = document.documentElement.scrollTop;
      var navBarTop =
        document.getElementById("miaosha").offsetTop +
        document.getElementsByClassName("main")[0].offsetTop;

      if (pageScrollTop >= navBarTop) {
        self.isFixTabbar = true;
      } else {
        self.isFixTabbar = false;
      }
    };
  },
  watch: {
    showTabbar(newVal) {
      this.showTabbar = newVal;
    },
  },
};
</script>
<style lang="css" scoped>
/* 秒杀导航栏 */
.nav-bar-wrap {
  /* position: fixed; */
  height: 6rem;
  width: calc(100% - 0px);
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 1rem 0px;
  background: linear-gradient(
    90deg,
    rgba(255, 125, 136, 1) 0%,
    rgba(255, 37, 66, 1) 100%
  );
}
.nav-bar-wrap.fix-bar {
  position: fixed;
  top: 0;
  /* left: auto;
  right: auto;
  width: 846px; */
}

.nav-bar-placeholder {
  height: 6rem;
  padding: 1rem 0;
}

.nav-bar {
  /* width: auto; */
  width: 100%;
  height: 6rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  justify-content: space-between;
  padding: 0 60px;
  box-sizing: border-box;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1.5rem;
  width: 10rem;
  box-sizing: border-box;
  color: #9b9b9b;
  font-size: 1.4rem;
  flex-shrink: 0;
  cursor: pointer;
}

.nav-item.active {
  color: #ff4c61;
  font-weight: bold;
}

.nav-name {
  line-height: 1.4rem;
}

.nav-item .duration-time {
  height: 2.1rem;
  font-size: 2.1rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
  line-height: 2.1rem;
  opacity: 0.5;
}

.nav-item .duration-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 1.4rem;
  opacity: 0.5;
  margin-top: 0.8rem;
}

.nav-item.active .duration-time {
  opacity: 1;
}

.nav-item.active .duration-text {
  opacity: 1;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 0.2rem 1rem;
  color: #ff4c61;
  text-align: center;
}
</style>
